<template>
    <template v-if="doctors">
        <div class="sb-inner" v-if="doctors.length > 0">
            <div class="sb-card" v-for="(doctor) in doctors" :key="doctor?.id">
                <router-link :to="'/dokter/' + doctor.specialist_slug + '/' + doctor.slug">
                    <div class="sbc-content">
                        <div class="sbc-image" v-if="doctor.profile">
                            <img :src="doctor.profile" :alt="doctor.name"/>
                        </div>
                        <div class="sbc-image sbc-image-empty" v-else>
                            <img src="../../assets/images/pictures/emptyphoto.jpg" alt="melinda hospital"/>
                        </div>
                        <div class="sbc-detail">
                            <div class="sd-specialist">
                                <h3>{{ doctor.name }}</h3>
                                <span v-if="doctor.specialist_parent_name && doctor.specialist_parent_name != doctor.specialist_name">{{ doctor.specialist_parent_name }} - </span><span>{{ doctor.specialist_name }}</span>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="search-not-found" v-else>
            <p>
                <span>Tidak ditemukan dokter dengan spesialis yang anda pilih </span>
                <span v-if="searchbarSpecialist.input.value || searchbarSelectSpecialist.input.value">dan dengan kata kunci:</span>
            </p>
            <div v-if="searchbarSpecialist.input.value">"{{ searchbarSpecialist.input.value }}"</div>
            <div v-if="searchbarSelectSpecialist.input.value.length > 0">"{{ searchbarSelectSpecialist.input.value }}"</div>
        </div>
        <div class="page-more">
            <div class="next-loading" v-if="nextLoading">
                <div class="loader-next"></div>
            </div>
            <div class="page-next" v-if="metaPage?.to < metaPage?.total">
                <span @click="nextPage">Selanjutnya...</span>
            </div>
        </div>
    </template>
</template>
<script setup>
    import { computed } from "@vue/runtime-core";
    import { useDoctorStore } from "../../stores/doctor";
    import { nextPage } from "../../functions/doctor";
    const doctors = computed(() => useDoctorStore().filteredDoctors);
    const searchbarSpecialist = computed(() => useDoctorStore().searchbarSpecialist);
    const searchbarSelectSpecialist = computed(() => useDoctorStore().searchbarSelectSpecialist);
    const metaPage = computed(() => useDoctorStore().metaPage);
    const nextLoading = computed(() => useDoctorStore().nextLoading);
</script>
<style scoped>
    @import url('../../assets/css/loaders/loader-next.css');
    .section-body{
        width: 100%;
    }
    .sb-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .sb-card{
        position: relative;
        width: 25%;
        padding: 1%;
        padding-top: 1rem;
        transition: 0.2s;
        user-select: none;
    }
    .sb-card a{
        text-decoration: none;
        color: #575757;
    }
    .sbc-content{
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        transition: 0.2s;
        background: var(--white);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .sbc-content:hover{
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.37), 0 6px 20px 0 rgba(0, 0, 0, 0.37);
    }
    .sbc-image{
        width: 100%;
        /* min-height: 250px; */
        height: 390px;
        /* max-height: 390px; */
        overflow: hidden;
        background: lightgrey;
        transition: 0.2s;
    }
    .sbc-image img{
        width: 100%;
    }
    .sbc-image-empty{
        display: flex;
        align-items: center;
    }
    .sd-specialist{
        background: var(--primary);
        color: var(--white);
        padding: 1rem;
        line-height: 1;
    }
    .sd-specialist span{
        font-size: 0.75rem;
    }
    .sd-stats{
        border-top: 1px solid var(--smoke);
        padding-top: 1rem;
    }
    .sd-stats-row{
        display: flex;
        flex-wrap: nowrap;
    }
    .sd-stats-param{
        width: 45%;
    }
    .sd-stats-value{
        width: 55%;
        padding-left: 1rem;
    }
    .sd-stats-tip{
        font-size: 0.9rem;
        font-style: italic;
        color: var(--softgrey);
        margin: 0;
        margin-top: 1rem;
    }
    .sbc-detail h3{
        font-family: poppins;
        text-align: left;
        color: var(--dark-grey);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0;
        line-height: 1;
    }
    .search-not-found{
        font-family: Arial, Helvetica, sans-serif;
        font-style: italic;
        text-align: center;
        color: var(--grey);
        word-break: break-all;
    }
    .page-more{
        padding: 0.5rem 1rem 0.5rem 1rem;
        display: flex;
        justify-content: center;
        color: var(--primary);
        font-style: italic;
        /* cursor: pointer; */
        user-select: none;
        transition: 0.2s;
    }
    .page-more:hover{
        color: var(--primary-hover);
    }
    .page-next span{
        cursor: pointer;
    }
    .next-loading{
        padding: 2.5rem 0 0 0;
    }
    @media only screen and (max-width: 1415px){
        .sb-card{
            width: 25%;
        }
        .sbc-image{
            height: 310px;
            /* max-height: 310px; */
        }
    }
    @media only screen and (max-width: 1100px){
        .sb-card{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 750px){
        .sb-card{
            width: 50%;
        }
        .sbc-content{
            box-shadow: unset;
            border: 1px solid var(--softgrey);
            border-radius: 5px;
        }
    }
    @media only screen and (max-width: 650px){
        .sb-card{
            min-width: 250px;
        }
    }
    @media only screen and (max-width: 550px){
        .sb-card{
            width: 100%;
        }
        .sbc-image{
            height: 410px;
            /* max-height: 410px; */
        }
    }
    @media only screen and (max-width: 410px){
        .sbc-image{
            height: 350px;
            /* max-height: 350px; */
        }
    }
    @media only screen and (max-width: 250px){
        .sbc-image{
            height: 310px;
            /* max-height: 310px; */
        }
    }
</style>