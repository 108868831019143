<template>
    <div class="content">
        <TopBackgroundComponent/>
        <CrumbComponent :data="crumbdata"/>
        <ContactHeader />
        <ContactForm />
        <!-- <ContactMaps /> -->
    </div>
</template>

<script setup>
    import TopBackgroundComponent from "../components/TopBackgroundComponent.vue";
    import CrumbComponent from '@/components/CrumbComponent.vue';
    import ContactHeader from '@/components/contact-us/ContactHeader.vue';
    import ContactForm from '@/components/contact-us/ContactForm.vue';
    // import ContactMaps from '@/components/contact-us/ContactMaps.vue';
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'Melinda Hospital - Contact Us',
            description: 'Kami berkomitmen dalam menyediakan layanan kesehatan kualitas terbaik.',
            linkTag: {
                rel: 'canonical',
                href: 'https://melindahospital.com/contact-us'
            },
            ogData: {
                title: 'Melinda Hospital - Contact Us',
                type: 'website',
                url: 'https://melindahospital.com/contact-us',
                image: 'https://backend1.melindadev.com/images/art_cover/63045ffd12602.jpg'
            }
        });
    });
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        {
            title: 'Contact Us',
            type: 'text'
        }
    ];
    // document.title = 'Contact Us - Melinda Care';
    window.scrollTo(0, 0);
</script>
