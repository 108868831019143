import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import { setPageData, wordsLimit, removeHTML } from "@/functions/general.js";
import { initArticleIndex, replaceUrls, setReference } from "@/functions/article.js";
import { paths } from "@/equipments/paths.js";

export const useArticleStore = defineStore("article", {
    state: () => ({
        // ==================== states used on artikel page and its relation
        allArticles: null,
        articles: null,
        filteredArticles: null,
        categories: null,
        filteredCategories: null,
        searchbarSelectCategory: {id: null, input: {value: ''}},
        searchbarCategory: {select: {id: null, title: 'Semua', value: 'all'}, input: {value: null}},
        autoSelectCategoryData: null,
        metaPage: null,
        nextLoading: false,
        articlesPageScrollTop: 0,
        articleDetail: null,
        articleDetailIndex: null,
        lastSlug: null,
        // ===============================
        articles12: null,
        articleCategories: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on artikel page and its relation
        async getAll(){
            this.resetFilter();
            try{
                if(!this.allArticles){
                    const response = await axiosClient.get(`article/content`);
                    this.allArticles = response.data.data;
                    this.metaPage = response.data.meta;
                    // console.log(response);
                }
                // setTimeout(function(){
                //     console.log(`scroll to: ${this.articlesPageScrollTop}`);
                //     scrollTo(0, this.articlesPageScrollTop);
                // }, 1000);
            }catch(error){
                console.log(error);
            }
        },
        async setArticles(){
            await this.getAll();
            await this.setCategories();
            this.filteredArticles = this.allArticles.slice(0, 12);
        },
        async setCategories(){
            try{
                if(!this.categories){
                    const response = await axiosClient.get(`article/category`);
                    this.categories = response.data.data;
                    this.filteredCategories = response.data.data;
                    // console.log(response);
                }
            }catch(error){
                console.log(error);
            }
        },
        // =======================
        inputSelectCategory(value){
            // this.metaPage = null;
            this.searchbarSelectCategory.input.value = value;
            this.searchArticleAndCategoryFromServer();
            // console.log(this.categories);
            if(this.categories && this.allArticles){
                this.searchbarSelectCategory.input.value = value;
                if(value.length){
                    var newsd = [];
                    newsd = this.categories;
                    var result = [];
                    var inval = new RegExp(value, 'gi');
                    for(var i = 0; i < newsd.length; i++){
                        if(newsd[i].name.match(inval)){
                            result.push(newsd[i]);
                        }
                    }
                    this.filteredCategories = result;
                }else{
                    this.filteredCategories = this.categories;
                }
                // console.log(this.filteredCategories);
                // if(this.allArticles && value.length > 0){
                //     var newsdx = [];
                //     newsdx = this.allArticles;
                //     // console.log(newsdx);
                //     if(this.filteredCategories){
                //         var resultx = [];
                //         for(i = 0; i < this.filteredCategories.length; i++){
                //             for(var j = 0; j < newsdx.length; j++){
                //                 if(newsdx[j].category.slug == this.filteredCategories[i].slug){
                //                     resultx.push(newsdx[j]);
                //                 }
                //                 // for(var k = 0; k < newsdx[j].categories.length; k++){
                //                 //     if(newsdx[j].categories[k].slug == this.filteredCategories[i].slug){
                //                 //         resultx.push(newsdx[j]);
                //                 //     }
                //                 // }
                //             }
                //         }
                //         // console.log(resultx);
                //         let uniqueresult = [...new Set(resultx.map(item => item))];
                //         // console.log(uniqueresult);
                //         // =============================
                //         var resulty = [];
                //         if(this.searchbarCategory.input.value){
                //             var invalspec = new RegExp(this.searchbarCategory.input.value, 'gi');
                //             for(i = 0; i < uniqueresult.length; i++){
                //                 if(uniqueresult[i].title.match(invalspec)){
                //                     resulty.push(uniqueresult[i]);
                //                 }
                //             }
                //         }
                //         // =============================
                //         if(uniqueresult.length){
                //             if(this.searchbarCategory.input.value){
                //                 this.filteredArticles = resulty;
                //             }else{
                //                 this.filteredArticles = uniqueresult;
                //                 // console.log('result x');
                //             }
                //         }else{
                //             this.filteredArticles = [];
                //         }
                //     }else{
                //         this.filteredArticles = [];
                //     }
                // }else{
                //     if(this.searchbarCategory.select.value != 'all'){
                //         this.searchServiceAndCategory();
                //     }else{
                //         this.filteredArticles = null;
                //     }
                // }
                // console.log(this.filteredArticles);
            }
        },
        selectCategory(category){
            // this.metaPage = null;
            // this.searchbarSpecialist.select = {title: specialist.name, value: specialist.slug};
            // if(this.searchbarSpecialist.select.value != 'all'){
            //     this.searchDoctorAndSpecialistFromServer();
            // }else{
            //     this.filteredDoctors = null;
            // }
            // // this.searchDoctorAndSpecialist();
            // this.filteredSpecialist = this.specialists;
            // =====
            this.searchbarCategory.select = {title: category.name, value: category.slug};
            this.searchArticleAndCategoryFromServer();
            // if(this.searchbarCategory.select.value != 'all'){
            // }else{
            //     this.filteredArticles = null;
            // }
            // this.searchServiceAndCategory();
            this.filteredCategories = this.categories;
        },
        inputSpecialist(value){
            // this.metaPage = null;
            this.searchbarCategory.input.value = value;
            if(this.searchbarSelectCategory.input.value == ''){
                this.searchArticleAndCategoryFromServer();
                // if(value != ''){
                // }else{
                //     this.filteredArticles = null;
                // }
                // this.searchServiceAndCategory();
            }else{
                this.beforeSelectedSpecialist();
            }
        },
        beforeSelectedSpecialist(){
            var newsdx = [];
            newsdx = this.allArticles;
            if(this.filteredCategories){
                var resultx = [];
                for(var i = 0; i < this.filteredCategories.length; i++){
                    for(var j = 0; j < newsdx.length; j++){
                        if(newsdx[j].category.slug == this.filteredCategories[i].slug){
                            resultx.push(newsdx[j]);
                        }
                        // for(var k = 0; k < newsdx[j].practice.length; k++){
                        //     if(newsdx[j].practice[k].specialization.slug == this.filteredCategories[i].slug){
                        //         resultx.push(newsdx[j]);
                        //     }
                        // }
                    }
                }
                if(resultx.length){
                    var resulty = [];
                    var inval = new RegExp(this.searchbarCategory.input.value, 'gi');
                    for(i = 0; i < resultx.length; i++){
                        if(resultx[i].title.match(inval)){
                            resulty.push(resultx[i]);
                        }
                    }
                    this.filteredArticles = resulty;
                }else{
                    this.filteredArticles = [];
                }
            }else{
                this.filteredArticles = null;
            }
        },
        searchServiceAndCategory(){
            if(this.allArticles){
                var newsd = this.allArticles;
                var result = [];
                if(this.searchbarCategory.select.value != 'all'){ // if selected specialist
                    for(var j = 0; j < newsd.length; j++){
                        if(newsd[j].category.slug == this.searchbarCategory.select.value){
                            result.push(newsd[j]);
                        }
                        // for(var k = 0; k < newsd[j].categories.length; k++){
                        //     if(newsd[j].categories[k].slug == this.searchbarCategory.select.value){
                        //         result.push(newsd[j]);
                        //     }
                        // }
                        // for(var k = 0; k < newsd[j].practice.length; k++){
                        //     if(newsd[j].practice[k].specialization.slug == this.searchbarCategory.select.value){
                        //         result.push(newsd[j]);
                        //     }
                        // }
                    }
                }else{
                    if(this.searchbarCategory.input.value){
                        result = this.allArticles;
                    }
                }
                // console.log(result);
                var resultx = [];
                if(this.searchbarCategory.input.value){
                    var inval = new RegExp(this.searchbarCategory.input.value, 'gi');
                    for(var i = 0; i < result.length; i++){
                        if(result[i].name.match(inval)){
                            resultx.push(result[i]);
                        }
                    }
                }else{
                    resultx = result;
                }
                if(resultx.length){
                    this.filteredArticles = resultx;
                }else{
                    this.filteredArticles = null;
                }
                // console.log(this.filteredArticles);
            }
        },
        async searchArticleAndCategoryFromServer(){
            try{
                const namequery = this.searchbarCategory.input.value;
                const spquery = this.searchbarSelectCategory.input.value;
                const spcid = this.searchbarSelectCategory.id;
                this.filteredArticles = null;
                // if(namequery == null && spquery == '' && spcid == null){
                //     this.filteredArticles = null;
                // }else{
                    this.metaPage = null;
                    var endpoint = `article/content`;
                    const params = {
                        search: namequery,
                        category: spquery,
                        category_id: spcid
                    };
                    const response = await axiosClient.get(endpoint, {params: params});
                    this.filteredArticles = response.data.data;
                    this.metaPage = response.data.meta;
                    console.log(response);
                    // console.log(`namequery: ${namequery}, spquery: ${spquery}, spcid: ${spcid}`);
                // }
                // console.log(this.metaPage);
            }catch(error){
                console.log(error);
            }
        },
        searchSpecialist(){
            var newsd = [];
            newsd = this.categories;
            // console.log(newsd);
            if(this.searchbarCategory.input.value){
                var result = [];
                var inval = new RegExp(this.searchbarCategory.input.value, 'gi');
                for(var i = 0; i < newsd.length; i++){
                    if(newsd[i].name.match(inval)){
                        result.push(newsd[i]);
                    }
                }
                this.categoriesShow = result;
            }else{
                this.categoriesShow = newsd;
            }
        },
        resetFilter(){
            this.searchbarSelectCategory = {id: null, input: {value: ''}};
            this.searchbarCategory = {select: {id: null, title: 'Semua', value: 'all'}, input: {value: null}};
            this.filteredArticles = null;
            // this.metaPage = null;
        },
        // =======================
        async setArticles12(){
            const response = await axiosClient.get(`article/content?limit=12`);
            this.articles12 = response.data;
        },
        async setArticleDetail(slug){
            for(var i = 0; i < paths.length; i++){
                if(paths[i].old == 'articles/detail/' + slug){
                    location.href = process.env.BASE_URL + paths[i].new;
                    console.log(true);
                }
            }
            this.articleDetail = null;
            const response = await axiosClient.get(`article/content/detail/` + slug, {
                headers: {
                    "Page": window.location.origin + `/articles/detail/${slug}`
                }
            });
            if(response.data?.success){
                const scdata = replaceUrls(response.data.data.content);
                console.log(scdata);
                // response.data.data.relateds = null;
                // response.data.data.same_category = null;
                this.articleDetail = response.data.data;
                this.articleDetail.content = scdata.content;
                this.articleDetail.reference = scdata.reference;
                this.articleDetail.pathname = '/articles/detail/' + slug;
                setTimeout(function(){
                    setReference(scdata.reference);
                }, 500);
                setPageData({
                    title: this.articleDetail.name,
                    description: wordsLimit(removeHTML(this.articleDetail.content), 200),
                    linkTag: {
                        rel: 'canonical',
                        href: 'https://melindahospital.com/articles/detail/' + slug
                    },
                    ogData: {
                        title: this.articleDetail.name,
                        type: 'website',
                        url: 'https://melindahospital.com/articles/detail/' + slug,
                        image: this.articleDetail.thumb_url,
                        description: wordsLimit(removeHTML(this.articleDetail.content), 200)
                    }
                });
                setTimeout(function(){
                    if(document.getElementById('sl-content-text')){
                        initArticleIndex();
                    }
                }, 500);
                this.setArticleDetailRelated(slug);
                console.log(this.articleDetail);
            }else{
                location.href = process.env.BASE_URL + 'not-found';
            }
            console.log(response);
        },
        async setArticleDetailRelated(slug){
            try{
                const response = await axiosClient.get(`article/content/related/` + slug);
                this.articleDetail.relateds = response.data.data.relateds;
                this.articleDetail.same_category = response.data.data.same_category;
                console.log(response);
            }catch(error){
                console.log(error);
            }
        },
        // async setArticleDetail(slug){
        //     for(var i = 0; i < paths.length; i++){
        //         if(paths[i].old == 'articles/detail/' + slug){
        //             location.href = process.env.BASE_URL + paths[i].new;
        //             console.log(true);
        //         }
        //     }
        //     this.articleDetail = null;
        //     const response = await axiosClient.get(`article/content/` + slug);
        //     if(response.data?.success){
        //         const scdata = replaceUrls(response.data.data.content);
        //         console.log(scdata);
        //         this.articleDetail = response.data.data;
        //         this.articleDetail.content = scdata.content;
        //         this.articleDetail.reference = scdata.reference;
        //         this.articleDetail.pathname = '/articles/detail/' + slug;
        //         setTimeout(function(){
        //             setReference(scdata.reference);
        //         }, 500);
        //         setPageData({
        //             title: this.articleDetail.name,
        //             description: wordsLimit(removeHTML(this.articleDetail.content), 200),
        //             linkTag: {
        //                 rel: 'canonical',
        //                 href: 'https://melindahospital.com/articles/detail/' + slug
        //             },
        //             ogData: {
        //                 title: this.articleDetail.name,
        //                 type: 'website',
        //                 url: 'https://melindahospital.com/articles/detail/' + slug,
        //                 image: this.articleDetail.thumb_url,
        //                 description: wordsLimit(removeHTML(this.articleDetail.content), 200)
        //             }
        //         });
        //         setTimeout(function(){
        //             if(document.getElementById('sl-content-text')){
        //                 initArticleIndex();
        //             }
        //         }, 500);
        //         console.log(this.articleDetail);
        //     }else{
        //         location.href = process.env.BASE_URL + 'not-found';
        //     }
        //     console.log(response);
        // },
        checkPath(pathname){
            if(this.articleDetail?.pathname != undefined && this.articleDetail?.pathname != pathname && pathname.split('/')[1] == 'articles' && pathname.split('/')[2] == 'detail'){
                this.setArticleDetail(pathname.split('/')[3]);
                console.log(pathname.split('/')[3]);
                console.log(this.articleDetail?.pathname);
            }
        },
        async setArticleCategories(){
            try{
                if(!this.articleCategories){
                    const response = await axiosClient.get(`article/category`);
                    this.articleCategories = response.data;
                    // console.log(response);
                }
            }catch(error){
                console.log(error);
            }
        },
        async nextPage(){
            this.nextLoading = true;
            try{
                const namequery = this.searchbarCategory.input.value;
                const spquery = this.searchbarSelectCategory.input.value;
                const spcid = this.searchbarSelectCategory.id;
                const page = this.metaPage.current_page + 1;
                this.metaPage = null;
                // if(namequery == null && spquery == '' && spcid == null){
                //     this.filteredArticles = null;
                // }else{
                    var endpoint = `article/content`;
                    const params = {
                        search: namequery,
                        category: spquery,
                        category_id: spcid,
                        page: page
                    };
                    if(this.filteredArticles == null){
                        this.filteredArticles = this.articles;
                    }
                    const response = await axiosClient.get(endpoint, {params: params});
                    var i;
                    for(i = 0; i < response.data.data.length; i++){
                        this.filteredArticles.push(response.data.data[i]);
                        // console.log('menambah data ' + i);
                    }
                    // this.filteredArticles = response.data.data;
                    this.metaPage = response.data.meta;
                    this.nextLoading = false;
                    // console.log(response);
                    // console.log(`namequery: ${namequery}, spquery: ${spquery}, spcid: ${spcid}`);
                    // console.log(this.metaPage);
                    // console.log(this.filteredArticles);
                // }
            }catch(error){
                this.nextLoading = false;
                console.log(error);
            }
        }
    }
});