import { useArticleStore } from "@/stores/article";
import { removeHTML, blinkElement, stringToHash } from "./general";
// import { cmcIndex } from "./home";
let lastInputSelectCategory = '';
const debouncedInputSelectCategory = debounce((e) => {
    lastInputSelectCategory = e.target.value;
    useArticleStore().searchbarSelectCategory.id = null;
    useArticleStore().inputSelectCategory(lastInputSelectCategory);
}, 1000);
export function inputSelectCategory(e){
    debouncedInputSelectCategory(e);
    // useArticleStore().inputSelectCategory(e.srcElement.value);
    if(e.srcElement.value){
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'flex';
    }else{
        useArticleStore().searchbarSelectCategory.input.value = '';
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
}
let lastInputSpecialistValue = '';
const debouncedInputSpecialist = debounce((e) => {
    lastInputSpecialistValue = e.target.value;
    useArticleStore().inputSpecialist(lastInputSpecialistValue);
}, 1000);
export function inputSpecialist(e){
    debouncedInputSpecialist(e);
    // useArticleStore().inputSpecialist(e.srcElement.value);
    if(e.srcElement.value){
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'flex';
    }else{
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
}
export function debounce(func, delay, immediate = false) {
    let timer;
    return function (...args) {
        const executeImmediately = immediate && !timer;
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            if (!immediate) {
                func(...args);
            }
        }, delay);
        if (executeImmediately) {
            func(...args);
        }
    };
}
export function selectCategory(specialist, inputid){
    // console.log(specialist);
    if(document.getElementById(inputid)){
        const input = document.getElementById(inputid);
        input.value = null;
        input.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
    useArticleStore().searchbarSelectCategory.input.value = '';
    useArticleStore().searchbarSelectCategory.id = specialist.id;
    useArticleStore().selectCategory(specialist);
}
export function clearInputCategory(){
    // const input = document.getElementById('sii-input-specialist');
    // input.value = null;
    // useDoctorStore().searchbarSpecialist.input = {value: null};
    // if(useDoctorStore().searchbarSelectSpecialist.id == null && useDoctorStore().searchbarSelectSpecialist.input.value == ''){
    //     useDoctorStore().inputSpecialist('');
    // }else{
    //     useDoctorStore().searchDoctorAndSpecialistFromServer();
    // }
    // input.parentElement.nextElementSibling.children[0].style.display = 'none';
    // ===============
    const input = document.getElementById('sii-input-specialist');
    input.value = null;
    useArticleStore().searchbarCategory.input = {value: null};
    if(useArticleStore().searchbarSelectCategory.id == null && useArticleStore().searchbarSelectCategory.input.value == ''){
        useArticleStore().inputSpecialist('');
    }else{
        useArticleStore().searchArticleAndCategoryFromServer();
    }
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
}
export function clearInputSelectCategory(){
    // const input = document.getElementById('input-select-specialist');
    // input.value = '';
    // useDoctorStore().searchbarSelectSpecialist.input.value = '';
    // useDoctorStore().searchbarSelectSpecialist.id = null;
    // useDoctorStore().inputSelectSpecialist('');
    // useDoctorStore().searchDoctorAndSpecialistFromServer();
    // input.parentElement.nextElementSibling.children[0].style.display = 'none';
    // =====
    const input = document.getElementById('input-select-svcategory');
    input.value = '';
    useArticleStore().searchbarSelectCategory.input.value = '';
    useArticleStore().searchbarSelectCategory.id = null;
    useArticleStore().inputSelectCategory('');
    useArticleStore().searchArticleAndCategoryFromServer();
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
}
export function setAutoSelectCategoryData(data){
    useArticleStore().autoSelectCategoryData = data;
    // console.log(data);
}
export function autoSelectCategory(){
    // console.log('auto select');
    const data = useArticleStore().autoSelectCategoryData;
    if(data != null){
        selectCategory(data);
        useArticleStore().autoSelectCategoryData = null;
    }
}
export function nextPage(){
    useArticleStore().nextPage();
}
export function articleIndex(title, content){
    // console.log(content);
    const articles = [];
    var currentArticle = -1;
    var lasth2;
    var lasth3;
    var lasth4;
    var lasth5;
    var lasth6;
    var thisid;
    var i;
    articles.push(
        {
            name: 'H1',
            value: title,
            id: 'index-h1-0',
            children: []
        }
    );
    currentArticle = currentArticle + 1;
    lasth2 = -1;
    for(i = 0; i < content.children.length; i++){
        if(content.children[i].tagName == 'H1'){
            return;
            // thisid = 'index-' + content.children[i].tagName + '-' + (currentArticle + 1);
            // articles.push(
            //     {
            //         name: 'H1',
            //         value: removeHTML(content.children[i].innerHTML),
            //         id: thisid,
            //         children: []
            //     }
            // );
            // currentArticle = currentArticle + 1;
            // lasth2 = -1;
        }
        if(content.children[i].tagName == 'H2'){
            if(articles[currentArticle].children){
                thisid = 'index-' + content.children[i].tagName + '-' + currentArticle + '-' + (lasth2 + 1);
                articles[currentArticle].children.push(
                    {
                        name: 'H2',
                        value: removeHTML(content.children[i].innerHTML),
                        id: thisid,
                        children: []
                    }
                );
                lasth2 = lasth2 + 1;
                lasth3 = -1;
            }else{
                return;
            }
        }
        if(content.children[i].tagName == 'H3'){
            // console.log(articles);
            if(articles[currentArticle].children[lasth2]?.children){
                thisid = 'index-' + content.children[i].tagName + '-' + currentArticle + '-' + lasth2 + '-' + (lasth3 + 1);
                articles[currentArticle].children[lasth2].children.push(
                    {
                        name: 'H3',
                        value: removeHTML(content.children[i].innerHTML),
                        id: thisid,
                        children: []
                    }
                );
                lasth3 = lasth3 + 1;
                lasth4 = -1;
            }else{
                return;
            }
        }
        if(content.children[i].tagName == 'H4'){
            if(articles[currentArticle].children[lasth2]?.children[lasth3]?.children){
                thisid = 'index-' + content.children[i].tagName + '-' + currentArticle + '-' + lasth2 + '-' + lasth3 + '-' + (lasth4 + 1);
                articles[currentArticle].children[lasth2].children[lasth3].children.push(
                    {
                        name: 'H4',
                        value: removeHTML(content.children[i].innerHTML),
                        id: thisid,
                        children: []
                    }
                );
                lasth4 = lasth4 + 1;
                lasth5 = -1;
            }else{
                return;
            }
        }
        if(content.children[i].tagName == 'H5'){
            if(articles[currentArticle].children[lasth2]?.children[lasth3]?.children[lasth4]?.children){
                thisid = 'index-' + content.children[i].tagName + '-' + currentArticle + '-' + lasth2 + '-' + lasth3 + '-' + lasth4 + '-' + (lasth5 + 1);
                articles[currentArticle].children[lasth2].children[lasth3].children[lasth4].children.push(
                    {
                        name: 'H5',
                        value: removeHTML(content.children[i].innerHTML),
                        id: thisid,
                        children: []
                    }
                );
                lasth5 = lasth5 + 1;
                lasth6 = -1;
            }else{
                return;
            }
        }
        if(content.children[i].tagName == 'H6'){
            if(articles[currentArticle].children[lasth2]?.children[lasth3]?.children[lasth4]?.children[lasth5]?.children){
                thisid = 'index-' + content.children[i].tagName + '-' + currentArticle + '-' + lasth2 + '-' + lasth3 + '-' + lasth4 + '-' + lasth5 + '-' + (lasth6 + 1);
                articles[currentArticle].children[lasth2].children[lasth3].children[lasth4].children[lasth5].children.push(
                    {
                        name: 'H6',
                        value: removeHTML(content.children[i].innerHTML),
                        id: thisid,
                        children: []
                    }
                );
                lasth6 = lasth6 + 1;
            }else{
                return;
            }
        }
        if(thisid != undefined){
            content.children[i].setAttribute('id', thisid);
        }
    }
    // console.log(articles);
    return articles;
}
export function createHtmlIndex(data){
    var cover = document.createElement('div');
    const sivopt = {
        behavior: 'auto',
        block: 'center',
        inline: 'center'
    };
    cover.classList.add('adcover-index');
    for(var i = 0; i < data.length; i++){
        var coverh1 = document.createElement('div');
        var elh1 = document.createElement('div');
        var elh1text = document.createTextNode(removeHTML(data[i].value));
        elh1.setAttribute('target', data[i].id);
        elh1.classList.add('adindex-main-title');
        coverh1.classList.add('adcover-index-h1');
        elh1.appendChild(elh1text);
        coverh1.appendChild(elh1);
        elh1.addEventListener('click', function(){
            document.getElementById(this.getAttribute('target')).scrollIntoView(sivopt);
            soiIndexHide();
            // blinkElement(this.getAttribute('target'));
        });
        if(data[i].children.length){
            // console.log('loop: ' + i);
            for(var j = 0; j < data[i].children.length; j++){
                var coverh2 = document.createElement('div');
                var headelh2 = document.createElement('div');
                var accelh2 = document.createElement('div');
                // var accelh2text = document.createTextNode('v');
                var elh2 = document.createElement(data[i].children[j].name);
                var elh2text = document.createTextNode(data[i].children[j].value);
                // accelh2.appendChild(accelh2text);
                elh2.setAttribute('target', data[i].children[j].id);
                coverh2.classList.add('adcover-index-h2');
                elh2.appendChild(elh2text);
                if(data[i].children[j].children.length){
                    headelh2.appendChild(accelh2);
                }
                headelh2.appendChild(elh2);
                coverh2.appendChild(headelh2);
                elh2.addEventListener('click', function(){
                    document.getElementById(this.getAttribute('target')).scrollIntoView(sivopt);
                    soiIndexHide();
                    blinkElement(this.getAttribute('target'));
                });
                if(data[i].children[j].children.length){
                    for(var k = 0; k < data[i].children[j].children.length; k++){
                        // var pccoverh3 = document.createElement('div');
                        var coverh3 = document.createElement('div');
                        var elh3 = document.createElement(data[i].children[j].children[k].name);
                        var elh3text = document.createTextNode(data[i].children[j].children[k].value);
                        elh3.setAttribute('target', data[i].children[j].children[k].id);
                        coverh3.classList.add('adcover-index-h3');
                        elh3.appendChild(elh3text);
                        coverh3.appendChild(elh3);
                        elh3.addEventListener('click', function(){
                            document.getElementById(this.getAttribute('target')).scrollIntoView(sivopt);
                            soiIndexHide();
                            blinkElement(this.getAttribute('target'));
                        });
                        // coverh2.appendChild(coverh3);
                        if(data[i].children[j].children[k].children.length){
                            for(var l = 0; l < data[i].children[j].children[k].children.length; l++){
                                var coverh4 = document.createElement('div');
                                var elh4 = document.createElement(data[i].children[j].children[k].children[l].name);
                                var elh4text = document.createTextNode(data[i].children[j].children[k].children[l].value);
                                elh4.setAttribute('target', data[i].children[j].children[k].children[l].id);
                                coverh4.classList.add('adcover-index-h4');
                                elh4.appendChild(elh4text);
                                coverh4.appendChild(elh4);
                                elh4.addEventListener('click', function(){
                                    document.getElementById(this.getAttribute('target')).scrollIntoView(sivopt);
                                    soiIndexHide();
                                    blinkElement(this.getAttribute('target'));
                                });
                                // coverh3.appendChild(coverh4);
                                if(data[i].children[j].children[k].children[l].children.length){
                                    for(var m = 0; m < data[i].children[j].children[k].children[l].children.length; m++){
                                        var coverh5 = document.createElement('div');
                                        var elh5 = document.createElement(data[i].children[j].children[k].children[l].children[m].name);
                                        var elh5text = document.createTextNode(data[i].children[j].children[k].children[l].children[m].value);
                                        elh5.setAttribute('target', data[i].children[j].children[k].children[l].children[m].id);
                                        coverh5.classList.add('adcover-index-h5');
                                        elh5.appendChild(elh5text);
                                        coverh5.appendChild(elh5);
                                        elh5.addEventListener('click', function(){
                                            document.getElementById(this.getAttribute('target')).scrollIntoView(sivopt);
                                            soiIndexHide();
                                            blinkElement(this.getAttribute('target'));
                                        });
                                        // coverh4.appendChild(coverh5);
                                        if(data[i].children[j].children[k].children[l].children.length){
                                            for(var n = 0; n < data[i].children[j].children[k].children[l].children[m].children.length; n++){
                                                var coverh6 = document.createElement('div');
                                                var elh6 = document.createElement(data[i].children[j].children[k].children[l].children[m].children[n].name);
                                                var elh6text = document.createTextNode(data[i].children[j].children[k].children[l].children[m].children[n].value);
                                                elh6.setAttribute('target', data[i].children[j].children[k].children[l].children[m].children[n].id);
                                                coverh6.classList.add('adcover-index-h6');
                                                elh6.appendChild(elh6text);
                                                coverh6.appendChild(elh6);
                                                elh6.addEventListener('click', function(){
                                                    document.getElementById(this.getAttribute('target')).scrollIntoView(sivopt);
                                                    soiIndexHide();
                                                    blinkElement(this.getAttribute('target'));
                                                });
                                                coverh5.appendChild(coverh6);
                                            }
                                        }
                                        coverh4.appendChild(coverh5);
                                    }
                                }
                                coverh3.appendChild(coverh4);
                            }
                        }
                        // pccoverh3.appendChild(coverh3);
                        coverh2.appendChild(coverh3);
                    }
                }
                coverh1.appendChild(coverh2);
            }
        }
        cover.appendChild(coverh1);
    }
    return cover;
}
export function cmcIndex(){
    const index = document.getElementById('soi-index');
    // const shadow = document.getElementById('cmc-index-shadow');
    if(index.classList.contains('cmc-index-active')){
        index.classList.remove('cmc-index-active');
        // shadow.classList.remove('cis-active');
    }else{
        index.classList.add('cmc-index-active');
        // shadow.classList.add('cis-active');
    }
}
export function initArticleIndex(){
    useArticleStore().articleDetailIndex = null;
    // console.log(document.getElementById('sl-content-text'));
    const title = document.getElementById('index-h1-0');
    const indexResult = articleIndex(title.innerHTML, document.getElementById('sl-content-text'));
    if(indexResult != undefined && indexResult[0].children.length){
        const htmlIndex = createHtmlIndex(indexResult);
        const cmcindex = document.getElementById('soi-index');
        cmcindex.innerHTML = '';
        cmcindex.appendChild(htmlIndex);
        useArticleStore().articleDetailIndex = indexResult;
    }
    console.log(indexResult);
}
export function soiIndexToggle(){
    const srindex = document.getElementById('sr-index');
    // const si = document.getElementById('soi-index');
    if(srindex.classList.contains('sr-index-active')){
        srindex.classList.remove('sr-index-active');
        // si.classList.remove('soi-index-active');
    }else{
        srindex.classList.add('sr-index-active');
        // si.classList.add('soi-index-active');
    }
}
export function soiIndexHide(){
    const srindex = document.getElementById('sr-index');
    srindex.classList.remove('sr-index-active');
    // if(si.classList.contains('soi-index-active')){
    //     si.classList.remove('soi-index-active');
    // }else{
    //     si.classList.add('soi-index-active');
    // }
}
export function sourceFilter(data){
    var rtdata = '';
    const tocount = data.match(new RegExp("http", "g") || []);
    for(var i = 0; i < tocount.length; i++){
        rtdata = getFirstLink(data);
    }
    return rtdata;
    // var sources = [];
    // let newdata = data;
    // var newchars = '';
    // console.log(tocount.length);
    // if(newdata.indexOf('http') > -1){
    //     var splitted = newdata.split('');
    //     var i = newdata.indexOf('http');
    //     // console.log(splitted[i]);
    //     while(splitted[i] != ' ' || i == splitted.length){
    //         newchars = newchars + splitted[i];
    //         i++;
    //     }
    // }
    // sources.push(removeHTML(newchars));
    // for(var j = 0; j < sources.length; j++){
    //     rtdata = newdata.replace(sources[j], '[cite]');
    // }
    // newdata = rtdata;
    // console.log('process');
    // while(newdata.indexOf('http') > -1){
    // }
    // console.log(sources);
}
export function getFirstLink(data){
    if(data.indexOf('http') > -1){
        var sources = [];
        var rtdata = '';
        var newchars = '';
        var splitted = data.split('');
        var i = data.indexOf('http');
        // console.log(splitted[i]);
        while(splitted[i] != ' ' || i == splitted.length){
            newchars = newchars + splitted[i];
            i++;
        }
        sources.push(removeHTML(newchars));
        for(var j = 0; j < sources.length; j++){
            rtdata = data.replace(sources[j], '[cite]');
        }
        console.log(sources);
        return rtdata;
    }
    return data;
}
// export function sourceFilter(data){
//     // var datatest = 'ini adalah data percobaan (sumber: https://www.ncbi.nlm.nih.gov/books/NBK430732/)';
//     // var newdata = data;
//     var sources = [];
//     var rtdata = '';
//     console.log(data.indexOf('(sumber:'));
//     console.log(data.split('')[data.indexOf('(sumber:')]);
//     var newchars = '';
//     if(data.indexOf('(sumber:') > -1){
//         var splitted = data.split('');
//         var i = data.indexOf('(sumber:');
//         console.log(splitted[i]);
//         while(splitted[i] != ')' || i == splitted.length){
//             newchars = newchars + splitted[i];
//             i++;
//             // while(splitted[i] != ')' || i == splitted.length){
//             // }
//         }
//         newchars = newchars + ')';
//     }
//     sources.push(newchars);
//     for(var j = 0; j < sources.length; j++){
//         rtdata = data.replace(sources[j], '[cite]');
//         // console.log(data.replace(sources[j], '[cite]'));
//     }
//     // console.log(data);
//     return rtdata;
// }
export function replaceUrls(text) {
 
    // Put the URL to variable $1 after visiting the URL
    // const Rexp = /((http|https|ftp):\/\/[\w?=&./-;#~%-]+(?![\w\s?&./;#~%"=-]*>))/g;
    // const reference = text.match(Rexp);
    const Rexp = /((http|https|ftp):\/\/(?![\w-]*(www.melindahospital\.com|melindahospital\.com|www.rsmelinda2\.com|rsmelinda2\.com))[\w?=&./-;#~%-]+(?![\w\s?&./;#~%"=-]*>))/g;
    const reference = text.match(Rexp);
    // const Rexp = /(http|https|ftp):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/g;
    // const reference = text.match(Rexp);
    // =====
    // for(var i = 0; i < reference.length; i++){
    //     text.replace(reference[i], `[$i]`);
    // }
    
    // var thspec = Object.values(rsmel.data.data[i].specializations.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {}));
    const uniqueref = [...new Set(reference)];
    console.log(uniqueref);
    // console.log(new URL(uniqueref[0]));
    var aoref = [];
    for(var i = 0; i < uniqueref.length; i++){
        aoref.push(new URL(uniqueref[i]));
    }
    console.log(aoref);

    // Replace the RegExp content by HTML element
    // return text.replace(Rexp, "<a citedata='$1' target='_blank' rel='nofollow'>[referensi]</a>");
    return {content: text.replace(Rexp, "<span refdata='$1' class='article-reference' style='color: var(--primary); cursor: pointer; user-select: none; font-size: 0.75rem;'>[referensi]</span>"), reference: aoref};
}
// export function linkify(inputText) {
//     var replacedText, replacePattern1, replacePattern2, replacePattern3;

//     //URLs starting with http://, https://, or ftp://
//     replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
//     replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

//     //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
//     replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
//     replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

//     //Change email addresses to mailto:: links.
//     replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
//     replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

//     return replacedText;
// }
let lastSlugValue = '';
const debouncedCheckPath = debounce((pathname) => {
    lastSlugValue = pathname;
    useArticleStore().checkPath(lastSlugValue);
}, 1000);
export function checkPath(pathname){
    debouncedCheckPath(pathname);
    // useArticleStore().inputSpecialist(e.srcElement.value);
    // if(e.srcElement.value){
    //     e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'flex';
    // }else{
    //     e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'none';
    // }
}
export function setReference(reference){
    console.log(reference);
    const ar = document.getElementsByClassName('article-reference');
    for(var i = 0; i < ar.length; i++){
        for(var j = 0; j < reference.length; j++){
            var parsedUrl = new URL(ar[i].getAttribute('refdata'));
            // console.log(parsedUrl);
            if(parsedUrl.href == reference[j]){
                ar[i].innerHTML = `[${j + 1}]`;
                ar[i].addEventListener('click', function(e){
                    var parsedRef = new URL(e.target.getAttribute('refdata'));
                    var reference = document.getElementById('reference' + stringToHash(parsedRef.href));
                    reference.scrollIntoView(
                        {
                            behavior: 'auto',
                            block: 'center',
                            inline: 'center'
                        }
                    );
                    reference.style.color = 'var(--navy)';
                    reference.style.transition = '0.5s';
                    reference.style.textDecoration = 'underline';
                    setTimeout(function(){
                        reference.style.color = 'var(--primary)';
                    }, 1000);
                    setTimeout(function(){
                        reference.style.textDecoration = 'none';
                    }, 5000);
                    // console.log(stringToHash(parsedRef.href));
                });
            }else{
                console.log(ar[i].getAttribute('refdata'));
                console.log(reference[j]);
            }
        }
        console.log(ar);
    }
}