<template>
    <div class="content">
        <TopBackgroundComponent/>
        <CrumbComponent :data="crumbdata"/>
        <SpMcuComponent/>
    </div>
</template>
<script setup>
    import TopBackgroundComponent from "../components/TopBackgroundComponent.vue";
    import CrumbComponent from '@/components/CrumbComponent.vue';
    import SpMcuComponent from "../components/special-pages/SpMcuComponent.vue";
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    import { useSpecialPageStore } from '@/stores/specialpage';
    onMounted(() => {
        setPageData({
            title: 'Jaga Kesehatan Anda dengan Pemeriksaan Rutin',
            description: 'Kami berkomitmen dalam menyediakan layanan kesehatan kualitas terbaik.',
            linkTag: {
                rel: 'canonical',
                href: 'https://melindahospital.com/sp/mcu-melinda-hospital'
            },
            ogData: {
                title: 'Jaga Kesehatan Anda dengan Pemeriksaan Rutin',
                type: 'website',
                url: 'https://melindahospital.com/sp/mcu-melinda-hospital',
                image: 'https://melindahospital.com/img/coripic.ab3d52c2.png'
            }
        });
        useSpecialPageStore().reportConversion({slug: 'mcu-melinda-hospital', path: '/sp/mcu-melinda-hospital'});
    });
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        // {
        //     title: 'Artikel',
        //     type: 'link',
        //     to: '/articles'
        // },
        {
            title: 'Jaga Kesehatan Anda dengan Pemeriksaan Rutin',
            type: 'text'
        }
    ];
    window.scrollTo(0, 0);
</script>
<style scoped>
    /*  */
</style>
