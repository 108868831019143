<template>
    <div class="content">
        <!-- <TopBackgroundComponent/> -->
        <HomeTopComponent/>
        <HomeSpecialistListComponent/>
        <HomeCoeComponent/>
        <!-- <AdditionalHeadComponent/> -->
        <OurSubsidiariesComponent/>
        <!-- <CrumbComponent :data="crumbdata"/> -->
        <!-- <HomeTopBackgroundComponent/> -->
        <!-- <CoeCategoryListComponent/> -->
        <!-- <HomeHeaderComponent/> -->
        <HomeDoctorComponent/>
        <HomeArticlesComponent/>
        <!-- <HomeHospitalComponent/> -->
    </div>
</template>
<script setup>
    // import TopBackgroundComponent from "../components/TopBackgroundComponent.vue";
    import HomeTopComponent from "../components/home/HomeTopComponent.vue";
    import HomeSpecialistListComponent from "@/components/home/HomeSpecialistListComponent.vue";
    // import AdditionalHeadComponent from "@/components/home/AdditionalHeadComponent.vue";
    import OurSubsidiariesComponent from "../components/home/OurSubsidiariesComponent.vue";
    import HomeCoeComponent from "../components/home/HomeCoeComponent.vue";
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    import HomeDoctorComponent from "../components/home/HomeDoctorComponent.vue";
    import HomeArticlesComponent from "../components/home/HomeArticlesComponent.vue";
    // import HomeHospitalComponent from "../components/home/HomeHospitalComponent.vue";
    import { site } from '@/equipments/settings';
    import { useSpecialPageStore } from '@/stores/specialpage';
    onMounted(() => {
        setPageData({
            title: site.title,
            description: 'Melinda Hospital adalah jaringan rumah sakit terbaik di Kota Bandung, yang telah melayani masyarakat dengan dedikasi tinggi selama lebih dari dua dekade.',
            linkTag: {
                rel: 'canonical',
                href: 'https://melindahospital.com'
            },
            ogData: {
                title: site.title,
                type: 'website',
                url: 'https://melindahospital.com',
                image: 'https://backend1.melindadev.com/images/art_cover/63045ffd12602.jpg'
            }
        });
        useSpecialPageStore().reportConversion({slug: 'home', path: ''});
    });
    window.scrollTo(0, 0);
    // import CrumbComponent from '@/components/CrumbComponent.vue';
    // import HomeTopBackgroundComponent from "../components/home/HomeTopBackgroundComponent.vue";
    // import HomeHeaderComponent from "../components/home/HomeHeaderComponent.vue";
    // import CoeCategoryListComponent from "@/components/coe/CoeCategoryListComponent.vue";
    // const crumbdata = [
    //     {
    //         title: 'Home',
    //         type: 'link',
    //         to: '/'
    //     }
    // ];
</script>
<style scoped>
    /*  */
</style>