import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { getCookie, setCookie, elementInViewport, generateRandomHexString, 
    stringToHash 
} from '@/functions/general';
import { sessionExpiration, 
    site 
} from '@/equipments/settings';
import { mainDescription } from './functions/home';

createApp(App).use(createPinia()).use(router).mount('#app');
if(getCookie('appHash')){
    setCookie('appHash', getCookie('appHash'), sessionExpiration); // renew the cookie
}else{
    setCookie('appHash', stringToHash(JSON.stringify(site)), sessionExpiration);
}
if(stringToHash(JSON.stringify(site)) != getCookie('appHash')){
    setCookie('appHash', stringToHash(JSON.stringify(site)), sessionExpiration);
    location.reload(true);
}
console.log(stringToHash(JSON.stringify(site)));
console.log(getCookie('appHash'));
console.log(navigator);
if(getCookie('session')){
    setCookie('session', getCookie('session'), sessionExpiration); // renew the cookie
}else{
    setCookie('session', generateRandomHexString(64), sessionExpiration);
}
// ==================================================== swipe menu for mobile view starts
let touchstartX = 0;
let touchendX = 0;
const nbmob = document.getElementById('navbar-menu');
const nbmobbg = document.getElementById('navbar-menu-bg');
function handleGesture() {
    if(touchendX > touchstartX){
        document.getElementById('navbar-menu').style.left = '110%';
        document.getElementById('navbar-menu-bg').style.width = '0';
    }
}
nbmob.addEventListener('touchstart', e => {
    touchstartX = e.changedTouches[0].screenX
});
nbmob.addEventListener('touchend', e => {
    touchendX = e.changedTouches[0].screenX
    handleGesture();
});
nbmobbg.addEventListener('touchstart', e => {
    touchstartX = e.changedTouches[0].screenX
});
nbmobbg.addEventListener('touchend', e => {
    touchendX = e.changedTouches[0].screenX
    handleGesture();
});
// ==================================================== swipe menu for mobile view ends
// ==================================================== when window scrolled starts
window.onscroll = function(){
    const ltree = document.querySelector('.link-tree-icon');
    const gotop = document.getElementsByClassName('go-top'); // <================== scroll top icon
    var i;
    if(document.body.scrollTop > 100 || document.documentElement.scrollTop > 100){
        for(i = 0; i < gotop.length; i++){
            if(screen.width > 1500){
                gotop[i].style.bottom = '5%';
            }else if(screen.width > 750){
                gotop[i].style.bottom = '5%';
            }else{
                gotop[i].style.bottom = '5%';
            }
        }
        ltree.children[0].children[0].style.transform = 'rotate(-360deg)';
        ltree.style.right = '115px';
    }else{
        for(i = 0; i < gotop.length; i++){
            gotop[i].style.bottom = '-15%';
        }
        ltree.children[0].children[0].style.transform = 'rotate(0)';
        ltree.style.right = '50px';
    }
    if(document.getElementById('sr-index') && document.getElementById('sr-index-icon') && document.getElementById('soi-index')){
        const covsrindex = document.getElementById('cov-sr-index');
        const siicon = document.getElementById('sr-index-icon');
        const srindex = document.getElementById('sr-index');
        const soindex = document.getElementById('soi-index');
        if(document.body.scrollTop > 650 || document.documentElement.scrollTop > 650){
            if(screen.width > 1050){
                covsrindex.style.height = (siicon.scrollHeight + srindex.scrollHeight) + 'px';
            }else{
                covsrindex.removeAttribute('style');
            }
            siicon.classList.add('sii-scrolled');
            srindex.classList.add('sr-index-scrolled');
            soindex.classList.add('soi-index-scrolled');
        }else{
            if(screen.width > 1050){
                covsrindex.removeAttribute('style');
            }
            siicon.classList.remove('sii-scrolled');
            srindex.classList.remove('sr-index-scrolled');
            srindex.classList.remove('sr-index-active');
            soindex.classList.remove('soi-index-scrolled');
        }
    }
    const st = document.getElementsByClassName('sticky-top-board');
    if(st[0]){
        const stpsh = st[0].parentElement.offsetHeight - (st[0].offsetHeight - (66 - 16));
        if(document.body.scrollTop > 66 || document.documentElement.scrollTop > 66){
            if(document.body.scrollTop > stpsh || document.documentElement.scrollTop > stpsh){
                for(i = 0; i < st.length; i++){
                    st[i].classList.remove('stb-active');
                    st[i].classList.add('stb-absolute');
                }
            }else{
                for(i = 0; i < st.length; i++){
                    st[i].classList.remove('stb-absolute');
                    st[i].classList.add('stb-active');
                }
            }
        }else{
            for(i = 0; i < st.length; i++){
                st[i].classList.remove('stb-absolute');
                st[i].classList.remove('stb-active');
            }
        }
    }
    const ob = document.getElementById('sr-order-body');
    if(ob){
        if(screen.width < 1051){
            if(document.body.scrollTop > ((document.body.scrollHeight - screen.height) - 1) || document.documentElement.scrollTop > ((document.body.scrollHeight - screen.height) - 1)){
                if(ob.offsetHeight > 0){
                    ob.style.maxHeight = 0;
                    ob.previousElementSibling.children[0].innerHTML = 'Tampilkan';
                    ob.classList.add('hide-by-scroll');
                }
            }else{
                if(ob.classList.contains('hide-by-scroll')){
                    ob.style.maxHeight = '500px';
                    ob.previousElementSibling.children[0].innerHTML = 'Sembunyikan';
                    ob.classList.remove('hide-by-scroll');
                }
            }
        }
    }
    // console.log(window.location);
    // if(window.location.pathname == '/articles'){
    //     if(document.body.scrollTop){
    //         useArticleStore().articlesPageScrollTop = document.body.scrollTop;
    //         console.log(document.body.scrollTop);
    //     }
    //     if(document.documentElement.scrollTop){
    //         useArticleStore().articlesPageScrollTop = document.documentElement.scrollTop;
    //         console.log(document.documentElement.scrollTop);
    //     }
    // }
}
// ==================================================== when window scrolled ends
// ==================================================== when window is clicked starts
window.onclick = function(e){
    if(e.target.classList.contains('navbar-login-form')){
        document.getElementById('navbar-login-form').style.display = 'none';
    }
    if(!e.target.classList.contains('trigger')){
        const option = document.getElementsByClassName('option');
        const slicon = document.getElementsByClassName('option-icon');
        // const float = document.getElementsByClassName('float');
        const flstage = document.getElementsByClassName('float-stage');
        const icon = document.getElementsByClassName('srv-button-icon');
        const ic = document.getElementById('srv-button-icon');
        for(var i = 0; i < option.length; i++){
            option[i].classList.remove('option-active');
        }
        for(i = 0; i < slicon.length; i++){
            slicon[i].classList.remove('option-icon-active')
        }
        if(ic){
            for(i= 0; i < flstage.length; i++){
                flstage[i].style.display = 'none';
            }
            ic.children[0].style.transform = 'rotate(0)';
        }
        // for(i = 0; i < float.length; i++){
        //     float[i].style.display = 'none';
        // }
        for(i = 0; i < icon.length; i++){
            if(!icon[i].classList.contains('icon-flstage')){
                icon[i].children[0].style.transform = 'rotate(0)';
            }
        }
    }
    if(e.target.classList.contains('modal')){
        const modal = document.getElementsByClassName('modal');
        for(i = 0; i < modal.length; i++){
            modal[i].classList.remove('modal-active');
        }
        // console.log('Modal');
    }
    if(e.target.classList.contains('cd-content')){
        mainDescription();
    }
}
// ==================================================== when window is clicked ends
// ==================================================== when window resized starts
window.onresize = function(){
    if(document.getElementById('nlf-content')){
        document.getElementById('nlf-content').scrollLeft = 0; // login form
    }
}
// ==================================================== when window resized ends
// ==================================================== when keydown detected starts
window.onkeydown = function(e){
    if(document.querySelector('.input-select')){
        const input = document.querySelector('.input-select');
        if(document.activeElement == input){
            const options = input.parentElement.parentElement.parentElement.nextElementSibling.children;
            var i;
            var active;
            if(e.keyCode == 40){
                active = 0;
                for(i = 0; i < options.length; i++){
                    if(options[i].classList.contains('option-button-active')){
                        if(options.length > i){
                            active = (i + 1);
                        }
                    }
                    options[i].classList.remove('option-button-active');
                }
                if(options[active]){
                    options[active].classList.add('option-button-active');
                    console.log(elementInViewport(options[active]));
                    // options[active].scrollIntoView();
                }else{
                    options[0].classList.add('option-button-active');
                    // options[0].scrollIntoView();
                }
            }else if(e.keyCode == 38){
                active = (options.length - 1);
                for(i = 0; i < options.length; i++){
                    if(options[i].classList.contains('option-button-active')){
                        if(options.length > i){
                            active = (i - 1);
                        }
                    }
                    options[i].classList.remove('option-button-active');
                }
                if(options[active]){
                    options[active].classList.add('option-button-active');
                    // options[active].scrollIntoView();
                }else{
                    options[(options.length - 1)].classList.add('option-button-active');
                    // options[(options.length - 1)].scrollIntoView();
                }
            }else if(e.keyCode == 13){
                for(i = 0; i < options.length; i++){
                    if(options[i].classList.contains('option-button-active')){
                        options[i].click();
                        // if(options.length > i){
                        //     active = (i + 1);
                        // }
                    }
                    options[i].classList.remove('option-button-active');
                }
            }
            // console.log('focus');
            // console.log(document.activeElement);
        }
    }
    if(document.querySelector('.input-select-two')){
        const input = document.querySelector('.input-select-two');
        if(document.activeElement == input){
            const options = input.parentElement.parentElement.parentElement.nextElementSibling.children;
            // var i;
            // var active;
            if(e.keyCode == 40){
                active = 0;
                for(i = 0; i < options.length; i++){
                    if(options[i].classList.contains('option-button-active')){
                        if(options.length > i){
                            active = (i + 1);
                        }
                    }
                    options[i].classList.remove('option-button-active');
                }
                if(options[active]){
                    options[active].classList.add('option-button-active');
                    console.log(elementInViewport(options[active]));
                    // options[active].scrollIntoView();
                }else{
                    options[0].classList.add('option-button-active');
                    // options[0].scrollIntoView();
                }
            }else if(e.keyCode == 38){
                active = (options.length - 1);
                for(i = 0; i < options.length; i++){
                    if(options[i].classList.contains('option-button-active')){
                        if(options.length > i){
                            active = (i - 1);
                        }
                    }
                    options[i].classList.remove('option-button-active');
                }
                if(options[active]){
                    options[active].classList.add('option-button-active');
                    // options[active].scrollIntoView();
                }else{
                    options[(options.length - 1)].classList.add('option-button-active');
                    // options[(options.length - 1)].scrollIntoView();
                }
            }else if(e.keyCode == 13){
                for(i = 0; i < options.length; i++){
                    if(options[i].classList.contains('option-button-active')){
                        options[i].click();
                        // if(options.length > i){
                        //     active = (i + 1);
                        // }
                    }
                    options[i].classList.remove('option-button-active');
                }
            }
            // console.log('focus');
            // console.log(document.activeElement);
        }
    }
    // console.log(e);
}
// ==================================================== when keydown detected ends
// ==================================================== functions start
// window.navigation.addEventListener("navigate", (event) => {
//     console.log('location changed!');
//     console.log(event);
// });
// window.addEventListener('locationchange', function () {
//     console.log('location changed!');
// });
// function syncChats(){
//     useChatStore().synchronizing();
//     // const chatDoctor = computed(() => useChatStore().chatDoctor);
//     // for(var i = 0; i < chatDoctor.length; i++){
//     // }
//     // console.log(chatDoctor);
// }
// setInterval(syncChats, 5000);
// ==================================================== functions end