<template>
    <div class="content">
        <TopBackgroundComponent/>
        <CrumbComponent :data="crumbdata"/>
        <CoeHeaderComponent/>
        <CoeCategoryListComponent/>
    </div>
</template>
<script setup>
    import TopBackgroundComponent from "../components/TopBackgroundComponent.vue";
    import CrumbComponent from '@/components/CrumbComponent.vue';
    import CoeHeaderComponent from "../components/coe/CoeHeaderComponent.vue";
    import CoeCategoryListComponent from "../components/coe/CoeCategoryListComponent.vue";
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'Melinda Hospital - Layanan',
            description: 'Kami berkomitmen dalam menyediakan layanan kesehatan kualitas terbaik.',
            linkTag: {
                rel: 'canonical',
                href: 'https://melindahospital.com/layanan'
            },
            ogData: {
                title: 'Melinda Hospital - Layanan',
                type: 'website',
                url: 'https://melindahospital.com/layanan',
                image: 'https://backend1.melindadev.com/images/art_cover/63045ffd12602.jpg'
            }
        });
    });
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        {
            title: 'Layanan Unggulan',
            type: 'text'
        }
    ];
    window.scrollTo(0, 0);
</script>
<style scoped>
    /*  */
</style>
