<template>
    <div class="content">
        <TopBackgroundComponent/>
        <CrumbComponent :data="crumbdata"/>
        <FindDoctorHeader/>
        <SpecialistListComponent/>
        <!-- <DoctorListComponent/> -->
    </div>
</template>
<script setup>
    import TopBackgroundComponent from "../components/TopBackgroundComponent.vue";
    import CrumbComponent from '@/components/CrumbComponent.vue';
    import FindDoctorHeader from "../components/doctor/FindDoctorHeader.vue";
    import SpecialistListComponent from "../components/doctor/SpecialistListComponent.vue";
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'Melinda Hospital - Cari Dokter',
            description: 'Kami berkomitmen dalam menyediakan layanan kesehatan kualitas terbaik.',
            linkTag: {
                rel: 'canonical',
                href: 'https://melindahospital.com/dokter'
            },
            ogData: {
                title: 'Melinda Hospital - Cari Dokter',
                type: 'website',
                url: 'https://melindahospital.com/dokter',
                image: 'https://backend1.melindadev.com/images/art_cover/63045ffd12602.jpg'
            }
        });
    });
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        {
            title: 'Cari Dokter',
            type: 'text'
        }
    ];
    window.scrollTo(0, 0);
    // import DoctorListComponent from "../components/doctor/DoctorListComponent.vue";
</script>
<style scoped>
    /*  */
</style>