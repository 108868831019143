<template>
    <section v-if="doctor">
        <div class="section-header">
            <div class="sh-inner" v-if="doctor">
                <div class="shi-image">
                    <div class="shi-image-inner" v-if="doctor.profile">
                        <img :src="doctor.profile" :alt="doctor.name"/>
                    </div>
                    <div class="shi-image-inner" v-else>
                        <img src="../../assets/images/pictures/emptyphoto.jpg" alt="melinda hospital"/>
                    </div>
                </div>
                <div class="shi-description">
                    <div class="sb-top">
                        <div class="sb-praktek">
                            <h1>{{ doctor.name }}</h1>
                            <h4><span v-if="doctor.specialist_first_name && doctor.specialist_first_name != doctor.specialist_name">{{ doctor.specialist_first_name }} - </span>{{ doctor.specialist_name }}</h4>
                            <div class="sb-praktek-tab">
                                <div class="sbi-tab-inner">
                                    <div class="sti-head">
                                        <div class="sti-head-button shb-first shb-active" id="shb-schedule" @click="showTab('sti-head-button', 'shb-schedule', 'sti-body-part', 'sbp-schedule', 'shb-active', 'sbp-active');">
                                            <span>Jadwal Praktek</span>
                                        </div>
                                        <div class="sti-head-button" id="shb-description" @click="showTab('sti-head-button', 'shb-description', 'sti-body-part', 'sbp-description', 'shb-active', 'sbp-active');">
                                            <span>Deskripsi</span>
                                        </div>
                                        <div class="sti-head-button" id="shb-info" @click="showTab('sti-head-button', 'shb-info', 'sti-body-part', 'sbp-info', 'shb-active', 'sbp-active');">
                                            <span>Informasi Lainnya</span>
                                        </div>
                                    </div>
                                    <div class="sti-body">
                                        <div class="sti-body-part sbp-active" id="sbp-schedule">
                                            <div class="sbp-content" v-if="doctor?.practices?.length">
                                                <div class="sc-card" v-for="(practice, index) in doctor.practices" :key="practice">
                                                    <div class="scc-image">
                                                        <img src="../../assets/images/pictures/melinda-logo.png" alt="melinda hospital"/>
                                                    </div>
                                                    <div class="sbc-description">
                                                        <div class="accordion" :class="{ 'accordion-li': index === (doctor.practices.length - 1) }">
                                                            <div class="accordion-button" :id="'acbutton-ddpractice-' + index" @click="accordion('acbutton-ddpractice-' + index)">
                                                                <div class="accordion-button-text">
                                                                    <h3>{{ practice.facility_name }}</h3>
                                                                    <h4><span v-if="practice.specialist_first_name && practice.specialist_first_name != practice.specialist_name">{{ practice.specialist_first_name }} - </span>{{ practice.specialist_name }}</h4>
                                                                </div>
                                                                <div class="accordion-button-icon">
                                                                    <img src="../../assets/images/icons/arrow-1.png" alt="melinda hospital"/>
                                                                </div>
                                                            </div>
                                                            <div class="accordion-content">
                                                                <div class="sbc-schedules" v-if="practice.schedules.length > 0">
                                                                    <div class="sbc-schedules-part" v-for="day in organizeSchedule(practice.schedules)" :key="day">
                                                                        <div class="ssp-inner">
                                                                            <div class="ssp-param">
                                                                                <span>{{ day.name }}</span>
                                                                            </div>
                                                                            <div class="ssp-value">
                                                                                <div class="ssp-value-schedule" v-for="schedule in day.schedules" :key="schedule">
                                                                                    <template v-if="schedule.starts && schedule.ends">
                                                                                        <div class="svs-starts">
                                                                                            <span>{{ schedule.starts }}</span>
                                                                                        </div>
                                                                                        <div class="svs-dash">
                                                                                            <span>-</span>
                                                                                        </div>
                                                                                        <div class="svs-ends">
                                                                                            <span>{{ schedule.ends }}</span>
                                                                                        </div>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <div class="svs-appointment">
                                                                                            <span>By Appointment</span>
                                                                                        </div>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="sbc-schedules" v-else>
                                                                    <p>Tidak ada jadwal</p>
                                                                </div>
                                                                <div class="sbc-phone">
                                                                    <p>Jadwal dokter dapat berubah sewaktu-waktu, informasi lebih lanjut hubungi <span>{{ practice.facility_name }},</span> Whatsapp: <a :href="'https://api.whatsapp.com/send?phone=' + practice.facility_phone + '&text=Halo,%20kami%20ingin%20mengetahui%20informasi%20lebih%20lanjut%20terkait%20jadwal%20praktek%20' + doctor.name" target="_blank" @click="useSpecialPageStore().reportConversion({slug: 'whatsapp-click-doctor-registration', path: `/dokter/${spslug}/${dcslug}`, phone: practice.facility_phone, facility_name: practice.facility_name, specialist_name: practice.specialist_name});">{{ practice.facility_phone }}</a></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="" v-else>
                                                <p>Belum ada Jadwal Praktek</p>
                                            </div>
                                        </div>
                                        <div class="sti-body-part" id="sbp-description">
                                            <div class="" v-if="doctor.description">
                                                <div class="sd-detail-description" v-html="doctor.description"></div>
                                            </div>
                                            <div v-else>
                                                <p>Belum ada Deskripsi</p>
                                            </div>
                                        </div>
                                        <div class="sti-body-part" id="sbp-info">
                                            <!-- <h2>Informasi Lainnya</h2> -->
                                            <div id="other-information" v-if="doctor.awards || doctor.organization || doctor.publication || doctor.education">
                                                <div class="sbp-content" v-if="doctor.awards">
                                                    <div class="accordion">
                                                        <div class="accordion-button" id="acbutton-dd-award" @click="accordion('acbutton-dd-award')">
                                                            <div class="accordion-button-text">
                                                                <h2>Penghargaan</h2>
                                                            </div>
                                                            <div class="accordion-button-icon">
                                                                <img src="../../assets/images/icons/arrow-1.png" alt="melinda hospital"/>
                                                            </div>
                                                        </div>
                                                        <div class="accordion-content">
                                                            <div v-html="doctor.awards"></div>
                                                            <!-- <p v-else>-</p> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="sbp-content" v-if="doctor.organization">
                                                    <div class="accordion">
                                                        <div class="accordion-button" id="acbutton-dd-org" @click="accordion('acbutton-dd-org')">
                                                            <div class="accordion-button-text">
                                                                <h2>Organisasi</h2>
                                                            </div>
                                                            <div class="accordion-button-icon">
                                                                <img src="../../assets/images/icons/arrow-1.png" alt="melinda hospital"/>
                                                            </div>
                                                        </div>
                                                        <div class="accordion-content">
                                                            <div v-html="doctor.organization"></div>
                                                            <!-- <p v-else>-</p> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="sbp-content" v-if="doctor.publication">
                                                    <div class="accordion">
                                                        <div class="accordion-button" id="acbutton-dd-pub" @click="accordion('acbutton-dd-pub')">
                                                            <div class="accordion-button-text">
                                                                <h2>Publikasi</h2>
                                                            </div>
                                                            <div class="accordion-button-icon">
                                                                <img src="../../assets/images/icons/arrow-1.png" alt="melinda hospital"/>
                                                            </div>
                                                        </div>
                                                        <div class="accordion-content">
                                                            <div v-html="doctor.publication"></div>
                                                            <!-- <p v-else>-</p> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="sbp-content" v-if="doctor.education">
                                                    <div class="accordion">
                                                        <div class="accordion-button" id="acbutton-dd-education" @click="accordion('acbutton-dd-education')">
                                                            <div class="accordion-button-text">
                                                                <h2>Pendidikan</h2>
                                                            </div>
                                                            <div class="accordion-button-icon">
                                                                <img src="../../assets/images/icons/arrow-1.png" alt="melinda hospital"/>
                                                            </div>
                                                        </div>
                                                        <div class="accordion-content">
                                                            <div v-html="doctor.education"></div>
                                                            <!-- <p v-else>-</p> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <p>Tidak ada Informasi Lainnya</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sb-related" v-if="doctor.others.length">
                                <h2>Dokter Lainnya</h2>
                                <div class="sb-related-inner">
                                    <div class="sri-part" v-for="dc in doctor.others" :key="dc">
                                        <router-link :to="'/dokter/' + dc.specialist_slug + '/' + dc.slug" @click="useDoctorStore().setDoctorProfile(dc.specialist_slug, dc.slug);">
                                            <div class="sci-dccard">
                                                <div class="sci-dccard-image">
                                                    <img :src="dc.profile" :alt="dc.name" v-if="dc.profile"/>
                                                    <img src="../../assets/images/pictures/emptyphoto.jpg" alt="melinda hospital" v-else/>
                                                </div>
                                                <div class="sci-dccard-text">
                                                    <h3>{{ dc.name }}</h3>
                                                    <h4>{{ dc.specialist_name }}</h4>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sb-kategori" v-if="specialists">
                            <div class="sb-kategori-separator">
                                <hr/>
                            </div>
                            <h2>Spesialis Lainnya</h2>
                            <div class="sbk-content">
                                <template v-for="sp in specialists.slice(0, 12)" :key="sp">
                                    <div class="sbk-content-inner">
                                        <div class="sci-card">
                                            <div class="sci-card-image">
                                                <img src="../../assets/favicon.png" alt="melinda hospital"/>
                                            </div>
                                            <div class="sci-card-text">
                                                <router-link :to="'/dokter'" @click="setAutoSelectSpecialistData(sp);">{{ sp.name }}</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="sbk-more">
                                <router-link to="/dokter" @click="resetFilter();">Selengkapnya...</router-link>
                            </div>
                            <br/>
                            <br/>
                            <!-- <h2>Dokter Lainnya</h2>
                            <div class="sbk-content">
                                <div class="sbk-content-inner" v-for="dc in doctor.others" :key="dc">
                                    <router-link :to="'/dokter/' + dc.specialist_slug + '/' + dc.slug" @click="useDoctorStore().setDoctorProfile(dc.specialist_slug, dc.slug);">
                                        <div class="sci-dccard">
                                            <div class="sci-dccard-image">
                                                <img :src="dc.profile" :alt="dc.name"/>
                                            </div>
                                            <div class="sci-dccard-text">
                                                <h3>{{ dc.name }}</h3>
                                                <h4>{{ dc.specialist_name }}</h4>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-body">
        </div>
    </section>
    <DoctorDetailSkeleton v-else/>
</template>

<script setup>
    import DoctorDetailSkeleton from '../skeletons/DoctorDetailSkeleton.vue';
    // import router from '@/router';
    import { useRoute } from 'vue-router';
    import { computed } from "@vue/runtime-core";
    import { useDoctorStore } from "../../stores/doctor";
    import { useSpecialPageStore } from "../../stores/specialpage";
    // import { useAccountStore } from '@/stores/account';
    // import { useTransactionStore } from '@/stores/transaction';
    import { useChatStore } from '@/stores/chat';
    import { showTab } from '@/functions/general';
    import { accordion } from '@/assets/javascript/accordion';
    import { 
        // selectPracticeLocation, 
        // setSchedules, 
        // scheduleToggle, 
        // showSop, 
        // validateSchedule, 
        // selectSchedule, 
        // confirmOrder, 
        organizeSchedule, setAutoSelectSpecialistData, resetFilter } from '@/functions/doctor';
    // import { scheduleToggle, showSop } from '@/functions/service';
    // import { prevMonth, nextMonth } from '@/functions/calendar';
    // import { maxAvailableService } from '@/equipments/settings';
    // import { setAutoSelectSpecialistData } from '@/functions/doctor';
    // import { onMounted } from 'vue';
    // const loggedIn = computed(() => useAccountStore().loggedIn);
    const doctor = computed(() => useDoctorStore().doctorProfile);
    const specialists = computed(() => useDoctorStore().specialists);
    const spslug = useRoute().params.specialist;
    const dcslug = useRoute().params.slug;
    // const thisDoctor = computed(() => useChatStore().thisDoctor);
    // const spslug = useRoute().params.specialist;
    // const date = new Date();
    // const doctorCalendar = computed(() => useDoctorStore().doctorCalendar);
    // const reservationSchedule = computed(() => useDoctorStore().reservationSchedule);
    // const doctorSchedule = computed(() => useDoctorStore().doctorSchedule);
    // const selectedSchedule = computed(() => useDoctorStore().selectedSchedule);
    useDoctorStore().setDoctorProfile(useRoute().params.specialist, useRoute().params.slug);
    useDoctorStore().setSpecialists();
    useChatStore().setThisDoctor(useRoute().params.slug);
    // onMounted(() => {
    //     setTimeout(function(){
    //         clickElement('acs-first');
    //     }, 500);
    //     // useDoctorStore().refreshDoctorDetail();
    // });
    // function chatNow(){
    //     const sf = document.getElementById('sds-chat-form');
    //     if(!sf.style.height){
    //         sf.style.height = sf.scrollHeight + 'px';
    //     }else{
    //         sf.style.height = null;
    //     }
    // }
    // function confirmOrder(detail, specialist, type, id){
    //     detail.specialist = specialist;
    //     useTransactionStore().setDirectOrder('service', type, {detail: detail}, id);
    //     useTransactionStore().setDirectOrderSchedule({payment: null});
    //     router.push('/order/service/confirmation');
    // }
    // function openDoctorChat(chat){
    //     const chatbox = document.getElementById('chat-box');
    //     const tg = document.getElementById('chat-box-body');
    //     const ic = document.getElementById('cbb-icon').children[0];
    //     chatbox.classList.add('chatbox-active');
    //     tg.classList.add('cbody-active');
    //     ic.style.transform = 'rotate(180deg)';
    //     document.getElementById('cbo-doctor').click();
    //     document.getElementById('cth-inner-' + chat).click();
    // }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
</script>

<style scoped>
    @import url('../../assets/css/tab.css');
    @import url('../../assets/css/accordion.css');
    section{
        /* position: relative; */
        padding-bottom: 2.5%;
        color: #575757;
        background: var(--white);
        /* min-height: 1000px; */
    }
    .section-header{
        /* background: #FEF8F0; */
        padding: 0 10% 2.5% 10%;
        transition: 0.2s;
    }
    .sh-inner{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        border-radius: 10px;
        /* padding: 1%; */
        /* background: #ffffff; */
    }
    .shi-image{
        /* position: relative; */
        /* display: flex; */
        width: 19%;
        /* transition: 0.2s;
        justify-content: center;
        align-items: start;
        overflow: hidden; */
        /* border-radius: 10px; */
        /* box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15); */
        /* border-radius: 0px;
        background: #e0e0e0;
        box-shadow:  27px 27px 6px #bebebe, -27px -27px 6px #ffffff; */
    }
    .shi-image-inner{
        border-radius: 0px;
        /* background: #e0e0e0; */
        box-shadow:  27px 27px 6px #bebebe, -2px -2px 6px #acacac;
    }
    .shi-image img{
        width: 100%;
        transition: 0.2s;
    }
    .shi-description{
        display: flex;
        flex-wrap: wrap;
        width: 81%;
        transition: 0.2s;
        /* border: 1px solid green; */
        padding: 0 0 0 2rem;
    }
    .sd-detail{
        width: 50%;
        padding: 0 1rem 1rem 1rem;
        transition: 0.2s;
    }
    .sd-detail h1{
        margin: 0;
        margin-bottom: 1rem;
    }
    /* .sd-label{
        margin-bottom: 2rem;
    }
    .sd-label span{
        background: #75C6EF;
        color: #ffffff;
        padding: 1%;
        padding-left: 2%;
        padding-right: 2%;
        border-radius: 5px;
    } */
    .ss-modal-button span{
        cursor: pointer;
    }
    .sd-stats{
        width: 50%;
        padding: 1%;
        transition: 0.2s;
    }
    .sds-count{
        color: blue;
        padding-right: 5px;
    }
    .sds-name{
        font-weight: 700;
        padding-left: 5px;
    }
    .sds-chat{
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100px;
        background: #ffffff;
        transition: 0.2s;
        padding: 0;
        display: none;
    }
    .sds-button{
        height: 2rem;
        margin-top: 2rem;
    }
    .sds-button-text{
        color: #ffffff;
        background: rgb(233, 147, 18);
        padding: 1%;
        padding-left: 2%;
        padding-right: 2%;
        border-radius: 5px;
        font-family: poppins;
        cursor: pointer;
        transition: 0.2s;
        user-select: none;
        white-space: nowrap;
    }
    .sds-button-text:hover{
        background: rgb(189, 119, 14);
    }
    .sds-chat-form{
        position: relative;
        height: 0;
        overflow: hidden;
        transition: height 0.2s ease-out;
    }
    .scs-inner{
        width: 100%;
        border: 1px solid lightgrey;
        border-radius: 5px;
        padding: 1rem;
        margin: 0 0 1rem 0;
    }
    .scs-inner-row{
        display: flex;
        width: 100%;
        margin-bottom: 1rem;
    }
    .sir-param{
        width: 50%;
    }
    .sir-value{
        width: 50%;
    }
    /* ========================= */
    .so-row{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 1rem;
    }
    .so-row-param{
        width: 37%;
    }
    .so-row-value{
        width: 63%;
    }
    .srv-select{
        position: relative;
        user-select: none;
    }
    .srv-button{
        display: flex;
        cursor: pointer;
        padding: 0.5rem;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .srv-button-text{
        display: flex;
        width: 90%;
    }
    .srv-button-text .sbt-price{
        padding-left: 0.5rem;
    }
    .srv-button-text .sbt-unit{
        padding-left: 1rem;
    }
    .sbt-price{
        width: 50%;
    }
    .sbt-unit{
        width: 50%;
    }
    .srv-button-icon{
        width: 10%;
        text-align: center;
    }
    .srv-button-icon img{
        transition: 0.2s;
    }
    .srv-option{
        position: absolute;
        top: 115%;
        left: 0;
        width: 100%;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        background: var(--white);
        display: none;
        padding: 0.5rem;
        z-index: 1;
    }
    .srv-option-location{
        z-index: 5;
    }
    .srv-option-part{
        display: none;
    }
    .sop-active{
        display: block;
    }
    .sop-schedule-head{
        display: flex;
        margin-bottom: 1rem;
    }
    .ssh-button{
        width: 15%;
    }
    .ssh-button-btn{
        padding: 0.5rem;
        cursor: pointer;
        user-select: none;
        font-size: 0.9rem;
    }
    .ssh-title{
        width: 85%;
        text-align: center;
    }
    .ssh-title-btn{
        padding: 0.5rem;
        cursor: pointer;
        user-select: none;
        font-size: 0.9rem;
    }
    .srv-option-button{
        display: flex;
        padding: 0.5rem;
        cursor: pointer;
        transition: 0.2s;
    }
    .srv-option-button:hover{
        background: var(--smoke);
    }
    .med-price{
        color: blue;
    }
    .ssb-inner-false{
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9rem;
        font-style: italic;
        color: var(--softgrey);
    }
    .service-calendar-head{
        display: flex;
        text-align: center;
    }
    .sch-button{
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: no;
    }
    .sch-title{
        width: 80%;
    }
    .scb-day{
        width: 100%;
        display: flex;
    }
    .scb-day-name{
        width: 25%;
        padding: 0.5rem;
        padding-left: 0;
    }
    .scb-day-date{
        width: 75%;
        display: flex;
    }
    .sdd-num{
        display: flex;
        width: 16.5%;
        text-align: center;
        justify-content: center;
    }
    .sdd-num-inner{
        position: relative;
        width: 100%;
    }
    .sni-available{
        position: absolute;
        width: 10px;
        height: 10px;
        top: 5px;
        right: 5px;
        background: rgba(39, 108, 197, 0.438);
        border-radius: 10px;
    }
    .sni-true{
        cursor: pointer;
    }
    .sni-true:hover{
        background: var(--smoke);
    }
    .sni-core{
        padding: 0.5rem 0 0.5rem 0;
        color: var(--grey);
    }
    .sni-core-active{
        background: var(--primary);
        color: var(--white);
    }
    .sni-core-outofrange{
        padding: 0.5rem;
    }
    /* ========================= */
    .scs-button{
        padding-top: 1rem;
    }
    .scs-button span{
        background: var(--primary);
        color: var(--white);
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
        user-select: none;
    }
    .section-body{
        padding-top: 2rem;
        padding-left: 9%;
        padding-right: 9%;
        transition: 0.2s;
    }
    .sb-top{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2.5%;
    }
    .sb-praktek{
        width: 75%;
        transition: 0.2s;
        padding: 0 2rem;
    }
    .sb-praktek h1{
        margin: 0 0 0.5rem 0;
        line-height: 1;
    }
    .sb-praktek h4{
        margin: 0 0 0 0;
        color: var(--primary);
    }
    .sb-praktek-tab{
        padding: 1rem 0 0 0;
    }
    .sbp-content{
        width: 100%;
        /* padding: 0 0 0.5rem 1rem; */
        /* border: 1px solid green; */
    }
    .sbp-content h2{
        margin: 0;
        font-size: 1.1rem;
    }
    .sb-kategori{
        width: 25%;
        border-radius: 10px;
        padding:  0 0.5rem 0.5rem 0.5rem;
        transition: 0.2s;
    }
    .sb-kategori h2{
        margin: 0;
        padding: 0 0 0.5rem 0;
        line-height: 1;
    }
    .sb-kategori-separator{
        display: none;
        padding: 1rem 2.5% 0 2.5%;
    }
    .sbk-content{
        width: 100%;
    }
    .sbk-content-inner{
        padding: 0.5rem 0 0.5rem 0;
    }
    .sci-card-image{
        display: none;
        /* border: 1px solid green; */
    }
    /* .sci-dccard{
        width: 50%;
        display: flex;
    }
    .sci-dccard-image{
    }
    .sci-dccard-image img{
        max-width: 50px;
    }
    .sci-dccard-text{
        font-size: 0.75rem;
        align-items: center;
        padding: 0 0 0 0.5rem;
    }
    .sci-dccard-text h3{
        margin: 0 0 0.5rem;
    }
    .sci-dccard-text h4{
        margin: 0;
        text-align: left;
        color: var(--navy);
    } */
    .sbk-more{
        margin: 1rem 0 0 0;
    }
    .sbk-more a{
        color: var(--grey);
    }
    .sc-card{
        display: flex;
        width: 100%;
        border-radius: 10px;
        background: var(--white);
        padding: 0 0 0 0;
    }
    .scc-image{
        display: none;
        width: 25%;
        align-items: start;
        padding: 0.5rem 0 0 0;
    }
    .scc-image img{
        width: 100%;
    }
    .sbc-description{
        width: 100%;
    }
    .sbc-description h3{
        margin: 0;
    }
    .sbc-schedules-part{
        padding: 0.5rem 0 0.5rem 0;
    }
    .ssp-inner{
        display: flex;
        flex-wrap: wrap;
        padding: 0.5rem;
    }
    .ssp-param{
        width: 40%;
    }
    .ssp-value{
        width: 60%;
    }
    .ssp-value-schedule{
        display: flex;
        text-align: center;
    }
    .svs-starts{
        min-width: 100px;
        text-align: left;
    }
    .svs-dash{
        min-width: 50px;
    }
    .svs-ends{
        min-width: 100px;
        text-align: right;
    }
    .svs-appointment{
        width: 100%;
        text-align: left;
    }
    .sb-related h2{
        padding-left: 1%;
    }
    .sb-related-inner{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0.5rem 0 0.5rem 0;
    }
    .sri-part{
        width: 50%;
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        transition: 0.2s;
    }
    .sri-part a{
        color: var(--grey);
    }
    .sci-dccard{
        display: flex;
        border: 1px solid var(--softgrey);
        border-radius: 0.5rem;
        padding: 0.5rem;
    }
    .sci-dccard-image{
        /*  */
    }
    .sci-dccard-image img{
        max-width: 50px;
        border: 1px solid var(--softgrey);
        border-radius: 0.5rem;
    }
    .sci-dccard-text{
        font-size: 0.75rem;
        align-items: center;
        padding: 0 0 0 0.5rem;
    }
    .sci-dccard-text h3{
        margin: 0 0 0.5rem;
    }
    .sci-dccard-text h4{
        margin: 0;
        text-align: left;
        color: var(--grey);
    }
    .sbr-name{
        padding-left: 5px;
    }
    .sbr-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .sb-card{
        position: relative;
        min-width: 290px;
        width: 25%;
        padding: 1%;
        transition: 0.2s;
    }
    .sb-card a{
        text-decoration: none;
        color: #575757;
    }
    .sbc-label{
        position: absolute;
        top: 7.5%;
        left: 10%;
    }
    .sbc-label span{
        padding: 5%;
        padding-left: 10px;
        padding-right: 10px;
        background: #75C6EF;
        color: #ffffff;
        border-radius: 50px;
    }
    .sbc-content{
        border: 1px solid rgb(230, 227, 227);
        border-radius: 10px;
        overflow: hidden;
        height: 450px;
    }
    .sbc-image{
        width: 100%;
        min-height: 150px;
        height: 41%;
        overflow: hidden;
        background: lightgrey;
    }
    .sbc-image img{
        width: 100%;
    }
    .sbc-detail{
        padding: 2.5%;
    }
    .sbc-detail h3{
        font-family: poppins;
        text-align: left;
        color: #575757;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
    .sbc-detail p{
        font-family: poppins-light;
        font-weight: 600;
        font-size: 0.9rem;
        text-align: justify;
        color: #575757;
        margin: 0;
    }
    @media only screen and (max-width: 1500px){
        .section-header{
            padding-left: 5%;
            padding-right: 5%;
        }
        .section-body{
            padding-left: 4%;
            padding-right: 4%;
        }
    }
    @media only screen and (max-width: 1415px){
        .sb-card{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 1100px){
        /* .shi-description{
            width: 100%;
            padding: 0;
        } */
        /* .shi-image{
            width: 25%;
        }
        .shi-description{
            width: 75%;
        }
        .sd-detail{
            width: 100%;
        }
        .sd-stats{
            width: 100%;
        } */
    }
    @media only screen and (max-width: 950px){
        /* .sb-praktek{
            width: 100%;
        } */
        /* .shi-image{
            width: 36%;
        }
        .shi-description{
            width: 64%;
        }
        .sb-card{
            width: 50%;
        } */
    }
    @media only screen and (max-width: 1100px){
        .section-header{
            padding-left: 0;
            padding-right: 0;
            /* border: 1px solid green; */
        }
        .section-body{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .shi-image{
            width: 100%;
            padding: 2.5%;
        }
        .shi-image-inner{
            box-shadow: unset;
        }
        .shi-image img{
            position: relative;
        }
        .shi-description{
            width: 100%;
            padding: 0;
        }
        .sd-detail{
            padding: 1rem 0 1rem 0;
        }
        .sb-praktek{
            width: 100%;
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sbp-content{
            /* padding: 0; */
        }
        .ssp-param{
            width: 100%;
        }
        .ssp-value{
            width: 100%;
            text-align: left;
        }
        .svs-starts{
            min-width: 50px;
        }
        .svs-dash{
            min-width: 25px;
        }
        .svs-ends{
            min-width: 50px;
        }
        .sb-kategori{
            width: 100%;
            padding: 0;
            /* border-top: 1px solid var(--softgrey); */
            /* border-radius: unset; */
        }
        .sb-kategori h2{
            padding: 1rem 2.5% 1rem 2.5%;
        }
        .sb-kategori-separator{
            display: block;
        }
        .sbk-content{
            display: flex;
            flex-wrap: wrap;
            padding: 1.5%;
        }
        .sbk-content-inner{
            width: 50%;
            height: 150px;
            padding: 0.5rem;
            /* border: 1px solid var(--softgrey); */
            /* margin: 0 1rem 1rem 0; */
        }
        .sci-card{
            width: 100%;
            height: 100%;
            display: flex;
            /* border: 1px solid var(--softgrey); */
            justify-content: left;
            align-items: center;
            padding: 0.5rem;
            /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
            border-radius: 5px;
            border: 1px solid var(--softgrey);
        }
        .sci-card-image{
            display: block;
        }
        .sci-card-text{
            padding: 0 0 0 1rem;
            /* color: var(--grey); */
        }
        .sci-card-text a{
            color: var(--grey);
        }
        .sbk-more{
            padding: 0 2.5% 0 2.5%;
        }
    }
    @media only screen and (max-width: 750px){
        .sri-part{
            width: 100%;
        }
        .sbk-content{
            padding: 1%;
        }
    }
    @media only screen and (max-width: 650px){
        .sb-card{
            min-width: 250px;
        }
        .sbk-content-inner{
            width: 100%;
        }
    }
    @media only screen and (max-width: 550px){
        .sb-card{
            width: 100%;
        }
    }
</style>
